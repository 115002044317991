import { useDispatch } from "react-redux";

import { AppDispatch } from "@/store";

import { resolveNotifications, ResolveNotificationsParams } from "./notificationThunks";
import { CancelProgramParams, cancelProgram } from "./programThunks";
import { setUserLogin } from "./slices/userSlice";

const useParentDispatch = () => {
    const dispatch = useDispatch<AppDispatch>();

    return {
        cancelProgram: (data: CancelProgramParams) =>
            dispatch(cancelProgram(data)),
        resolveNotifications: (data: ResolveNotificationsParams) =>
            dispatch(resolveNotifications(data)),
        setUserLogin: (args: {
            userName: string;
            rememberMe: boolean;
        }) => dispatch(setUserLogin(args))
    };
};

export default useParentDispatch;
